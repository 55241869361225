import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useCalendarsList() {
  // Use toast
  const toast = useToast();

  const refCalendarListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    {
      key: 'createdAt',
      label: 'Created At',
      formatter: (value) => {
        return moment(value).format('L');
      },
      sortable: true,
    },
    { key: 'category', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalCalendars = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const categoryFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refCalendarListTable.value ? refCalendarListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCalendars.value,
    };
  });

  const refetchData = () => {
    refCalendarListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, categoryFilter, statusFilter], () => {
    refetchData();
  });

  const fetchCalendars = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
    };

    if (categoryFilter.value) {
      pl.category = categoryFilter.value;
    }

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('app-roster/fetchCalendars', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalCalendars.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendars list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCalendarCategoryVariant = (status) => {
    if (status === 'standard') return 'primary';
    if (status === 'veteran') return 'info';
    if (status === 'full') return 'warning';
    return 'primary';
  };

  const resolveCalendarStatusVariant = (status) => {
    if (status === 'created') return 'info';
    if (status === 'active') return 'success';
    return 'primary';
  };

  const categoryOptions = [
    { label: 'Standard', value: 'standard' },
    { label: 'Veteran', value: 'veteran' },
    { label: 'Full', value: 'full' },
  ];

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];

  return {
    fetchCalendars,
    tableColumns,
    perPage,
    currentPage,
    totalCalendars,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCalendarListTable,

    resolveCalendarCategoryVariant,
    resolveCalendarStatusVariant,
    refetchData,

    categoryOptions,
    statusOptions,

    // Extra Filters
    categoryFilter,
    statusFilter,
  };
}
