<template>
  <div>
    <calendar-list-add-new
      :is-add-new-calendar-sidebar-active.sync="isAddNewCalendarSidebarActive"
      :category-options="categoryOptions"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <calendar-list-filters
      :category-filter.sync="categoryFilter"
      :status-filter.sync="statusFilter"
      :category-options="categoryOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewCalendarSidebarActive = true">
                <span class="text-nowrap">Add Calendar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCalendarListTable"
        class="position-relative"
        :items="fetchCalendars"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Calendar -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveCalendarStatusVariant(data.item.status)}`"
                :to="{ name: 'apps-roster-calendar-view', params: { id: data.item.id } }"
                rounded
              />
            </template>
            <b-link
              :to="{ name: 'apps-roster-calendar-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small v-if="data.item.category === 'veteran'" class="text-muted">
              {{ (data.item.pathways || []).length }} total pathways
            </small>
            <small v-else-if="data.item.category === 'standard'" class="text-muted">
              {{ (data.item.subjects || []).length }} total subjects
            </small>
            <small v-else class="text-muted">
              {{ (data.item.pathways || []).length + (data.item.subjects || []).length }} total pathways + subjects
            </small>
          </b-media>
        </template>

        <!-- Column: Live Sessions -->
        <template #cell(live_sessions)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.sessions.filter((session) => session.status === 'live').length
            }}</span>
          </div>
        </template>

        <!-- Column: Category -->
        <template #cell(category)="data">
          <b-badge pill :variant="`light-${resolveCalendarCategoryVariant(data.item.category)}`" class="text-capitalize">
            {{ data.item.category.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveCalendarStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`calendar-row-${data.item.id}-download-icon`"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="downloadCalendar(data.item)"
            />
            <b-tooltip title="Download PDF" class="cursor-pointer" :target="`calendar-row-${data.item.id}-download-icon`" />

            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item @click="downloadCalendar(data.item)">
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item>
              <b-dropdown-item @click="regenerateCalendar(data.item)">
                <feather-icon icon="RotateCwIcon" />
                <span class="align-middle ml-50">Re-Generate</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editCalendar(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteCalendar(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Remove</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <!-- Column: Actions -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCalendars"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import CalendarListFilters from './CalendarListFilters.vue';
import useCalendarsList from './useCalendarsList';
import rosterStoreModule from '../rosterStoreModule';
import CalendarListAddNew from './CalendarListAddNew.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import fileDownload from 'js-file-download';

export default {
  components: {
    CalendarListFilters,
    CalendarListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  methods: {
    async downloadCalendar(calendar) {
      const { id: calendarId, name } = calendar;

      this.disableDownload = true;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'SettingsIcon',
          variant: 'success',
          text: 'Your download is being initialized.',
        },
      });

      await store
        .dispatch('app-roster/downloadCalendarPdf', { calendarId })
        .then((response) => {
          const { data } = response;
          fileDownload(data, `${name}.pdf`);

          this.disableDownload = false;
        })
        .catch(async (error) => {
          console.log(`Failed to download calendar document`, error);
          this.disableDownload = false;

          if (error.response) {
            const { response } = error;

            let { data } = response;

            if (data instanceof Blob) {
              data = JSON.parse(await data.text());
            }

            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to download file.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: data.message || 'An unknown error has occurred.',
              },
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to download file.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          });
        });
    },

    editCalendar(data) {},
    deleteCalendar(data) {
      store.dispatch('app-roster/deleteCalendar', { calendarId: data.id }).then(() => {
        this.refetchData();
      });
    },
    regenerateCalendar(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'SettingsIcon',
          variant: 'success',
          text: 'Creating calendar document. This may take a few moments.',
        },
      });

      store
        .dispatch('app-roster/regenerateCalendar', { calendarId: data.id })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document Created',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'This calendar has been updated',
            },
          });

          this.refetchData();
        })
        .catch(async (error) => {
          console.log(`Failed to download calendar document`, error);
          this.disableDownload = false;

          if (error.response) {
            const { response } = error;

            let { data } = response;

            if (data instanceof Blob) {
              data = JSON.parse(await data.text());
            }

            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to download file.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: data.message || 'An unknown error has occurred.',
              },
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to download file.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          });
        });
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const isAddNewCalendarSidebarActive = ref(false);

    const {
      fetchCalendars,
      tableColumns,
      perPage,
      currentPage,
      totalCalendars,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCalendarListTable,
      refetchData,

      // UI
      resolveCalendarCategoryVariant,
      resolveCalendarStatusVariant,

      categoryOptions,
      statusOptions,

      // Extra Filters
      categoryFilter,
      statusFilter,
    } = useCalendarsList();

    return {
      // Sidebar
      isAddNewCalendarSidebarActive,

      fetchCalendars,
      tableColumns,
      perPage,
      currentPage,
      totalCalendars,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCalendarListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveCalendarCategoryVariant,
      resolveCalendarStatusVariant,

      categoryOptions,
      statusOptions,

      // Extra Filters
      categoryFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.calendar-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
